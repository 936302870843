import BaseApiService from "@/shared/api/BaseApiService";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { ProjectState } from "./planning/Project";
import { CircularProgress, IconButton, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ArrowForward } from "@mui/icons-material";
import EnhancedTable from "@/shared/views/EnhancedTable";

const headCells = [
  {
    id: "number",
    label: "Kostenstelle",
    numeric: true,
    sortable: true,
  },
  {
    id: "name",
    label: "Name",
    numeric: false,
    sortable: true,
  },
];

export default function ProjectList({ filter, title }) {
  const navigate = useNavigate();

  const query = useQuery<ProjectState[], AxiosResponse>({
    queryKey: ["getProjects", filter],
    queryFn: BaseApiService.getEntitiesWithFilter("project", filter),
  });

  return (
    <Paper sx={{ overflow: "hidden", display: "flex", flexDirection: "column" }}>
      <div
        style={{
          padding: "5px 10px",
          textTransform: "uppercase",
          fontWeight: "bold",
          color: "#555",
          fontSize: "15px",
          background: "#edf0f9",
          border: "1px solid rgba(0,0,0,0.2)",
          boxShadow:
            "inset 0px -4px 5px rgba(0,0,0,0.1)"
        }}
      >
        {title}
      </div>
      <div style={{display: "flex", flex: 1, overflow: "auto", justifyContent: "center", alignItems: "center"}}>
        {query.isError ? (
          "Error"
        ) : query.isLoading ? (
          <CircularProgress />
        ) : (query?.data?.length ?? 0) === 0 ? (
          <div>Nichts gefunden</div>
        ) : (
          <EnhancedTable
          sx={{flex: 1, alignSelf: "stretch"}}
            initialSortDir={"asc"}
            initialRows={10}
            initialSort={"name"}
            rows={query.data}
            filter
            headCells={headCells}
            buttons={(r) => (
              <IconButton onClick={() => navigate("/project/" + r.id)}>
                <ArrowForward />
              </IconButton>
            )}
          />
        )}
      </div>
    </Paper>
  );
}
