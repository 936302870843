import {
  Add,
  AddCircleTwoTone,
  ArrowForward,
  Delete,
  ExpandMore,
  RemoveCircleTwoTone,
  Search,
  StarRateRounded,
  X,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Fragment, useCallback, useState } from "react";
import LabelWrapper from "../../root/LabelWrapper";
import {
  Article,
  MaterialOrder,
  OrderPosition,
  ResourceOrder,
  SupplierOrder as SO,
} from "../Project";

import SupplierOrder from "../elements/SupplierOrder";
import TimePicker from "react-time-picker";
import ApiService from "@/api/ApiService";
import ErrorIndicator from "@/shared/dialogs/ErrorIndicator";
import EnhancedTable from "@/shared/views/EnhancedTable";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

const headCells = [
  {
    id: "number",
    label: "Artikelnummer",
    numeric: true,
    sortable: true,
  },
  {
    id: "name",
    label: "Artikelname",
    numeric: false,
    sortable: true,
  },
  {
    id: "plantName",
    label: "Lieferwerk",
    numeric: false,
    sortable: true,
  },
  {
    id: "supplierName",
    label: "Lieferant",
    numeric: false,
    sortable: true,
  }
];

export default function AddMaterialDialog({
  save,
  OpenButton,
  initialState,
  supplierOrders,
}: {
  supplierOrders: SO[];
  save: (order: MaterialOrder) => void;
  OpenButton: any;
  initialState: MaterialOrder;
}) {
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState("100");
  const [searchField, setSearchField] = useState("");
  const [manualSelection, setManualSelection] = useState(false);
  const [state, setState] = useState<MaterialOrder>(initialState);

  const search = useMutation<Article[], AxiosResponse, { search: string }>(
    
    {
      mutationFn: ApiService.searchMaterials(),
      onSuccess: (answer) => {
        console.log("answer");
      },
    }
  );

  const close = useCallback(() => {
    setOpen(false);
  }, []);

  const openAndSet = useCallback(() => {
    setState(initialState);
    setAmount("" + initialState.amount);
    setOpen(true);
  }, [initialState]);

  const valid = state.amount > 0 && !!state.article;

  /*
  thermo: boolean;
  truckType: "XX" | "SA" | "3A" | "4A";
  */

  console.log(state);
  return (
    <Fragment>
      <OpenButton onClick={openAndSet} />
      <Dialog open={open} maxWidth="lg" fullWidth onClose={close}>
        <Fragment>
          <DialogTitle
            sx={{
              p: 1,
              px: 2,
              background: "#002196",
              color: "white",
              borderBottom: "1px solid rgba(255,255,255,0.5)",
            }}
          >
            Material bestellen
          </DialogTitle>
          <DialogContent
            sx={{
              p: 0,
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="extraFlex">
              <LabelWrapper
                label={
                  <Fragment>
                    <Box>Artikelauswahl</Box>
                    {!state.article ? (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              size="small"
                              checked={manualSelection}
                              onChange={(e) =>
                                setManualSelection(e.target.checked)
                              }
                            />
                          }
                          componentsProps={{ typography: { fontSize: "90%" } }}
                          sx={{ m: "0px" }}
                          label="Freie Auswahl"
                        />
                      </FormGroup>
                    ) : null}
                  </Fragment>
                }
              >
                {!!state.article ? (
                  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    <Box>
                      <Box>{state.article.name}</Box>
                      <Box>{state.article.number}</Box>
                      <Box>{state.article.plantName}</Box>
                      <Box>{state.article.supplierName}</Box>
                      {!state.position ? <Box><em>Unbepreister Artikel!</em></Box> : null}
                    </Box>
                    <IconButton
                      onClick={() =>
                        setState((s) => ({
                          ...s,
                          position: null,
                          article: null,
                        }))
                      }
                    >
                      <Delete />
                    </IconButton>
                  </Box>
                ) : manualSelection ? (
                  <Fragment>
                    <OutlinedInput
                      sx={{ mb: "10px" }}
                      fullWidth
                      size="small"
                      onKeyDown={(e) =>
                        e.key === "Enter"
                          ? search.mutate({
                              search: searchField,
                            })
                          : null
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            disabled={search.isPending}
                            onClick={() =>
                              search.mutate({
                                search: searchField,
                              })
                            }
                            onMouseDown={(
                              event: React.MouseEvent<HTMLButtonElement>
                            ) => event.preventDefault()}
                            edge="end"
                          >
                            <Search />
                          </IconButton>
                        </InputAdornment>
                      }
                      placeholder="Artikel suchen..."
                      value={searchField}
                      onChange={(e) =>
                        setSearchField((e.target as HTMLInputElement).value)
                      }
                    />
                    {search.isIdle ? null : (
                      <Paper variant="outlined">
                        {search.isError ? (
                          <ErrorIndicator />
                        ) : search.isPending ? (
                          <Box
                            sx={{
                              minHeight: "100px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        ) : search.data.length === 0 ? (
                          <Alert severity="info">
                            <AlertTitle>Nichts gefunden.</AlertTitle>
                            Hinweis: Sie müssen mindestens drei Zeichen eingeben
                            und ein Lieferwerk auswählen.
                          </Alert>
                        ) : (
                          <EnhancedTable
                            buttons={(article: Article) => (
                              <IconButton
                                size="small"
                                aria-label="edit"
                                onClick={() =>
                                  setState((s) => ({
                                    ...s,
                                    position: null,
                                    article,
                                  }))
                                }
                              >
                                <ArrowForward />
                              </IconButton>
                            )}
                            type=""
                            initialSort="name"
                            headCells={headCells}
                            rows={search.data}
                          />
                        )}
                      </Paper>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    {supplierOrders.map((so, i) =>
                      so.deleted ? null : (
                        <SupplierOrder
                          so={so}
                          deleteOrder={null}
                          onSelect={(position: OrderPosition) =>
                            setState((s) => ({
                              ...s,
                              position,
                              article: position.article,
                            }))
                          }
                          key={i}
                        />
                      )
                    )}
                  </Fragment>
                )}
              </LabelWrapper>
              {!!state.article ? (
                <Fragment><LabelWrapper label="Menge">
                  <TextField
                    size="small"
                    fullWidth
                    value={amount}
                    placeholder="Weitere Informationen"
                    multiline
                    onChange={(e) => {
                      setAmount((e.target as HTMLInputElement).value);
                      setState((s) => ({
                        ...s,
                        amount:
                          parseInt((e.target as HTMLInputElement).value) || 0,
                      }));
                    }}
                  />
                </LabelWrapper>
                <LabelWrapper label="Erster LKW an BS">
                <TimePicker
                  onChange={(firstUnload) =>
                    setState((s) => ({
                      ...s,
                      firstUnload,
                    }))
                  }
                  className={!state.firstUnload ? "nostart" : ""}
                  disableClock
                  value={state.firstUnload}
                />
              </LabelWrapper></Fragment>
              ) : null}
            </div>
            {!!state.article ? (
              <Fragment>
                <Accordion disableGutters>
                  <AccordionSummary
                    sx={{
                      background: "#002169",
                      color: "white",
                      borderBottom: "1px solid rgba(255,255,255,0.5)",
                    }}
                    expandIcon={<ExpandMore sx={{ color: "white" }} />}
                  >
                    Logistik
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 0 }} className="extraFlex">
                    <LabelWrapper label="LKW-Anzahl">
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                          disabled={!state.truckAmount}
                          onClick={() =>
                            setState((s) => ({
                              ...s,
                              truckAmount: !s.truckAmount
                                ? null
                                : s.truckAmount - 1 || null,
                            }))
                          }
                        >
                          <RemoveCircleTwoTone />
                        </IconButton>
                        <Box sx={{ mx: "10px" }}>
                          {!state.truckAmount
                            ? "egal"
                            : state.truckAmount + " LKW"}
                        </Box>
                        <IconButton
                          onClick={() =>
                            setState((s) => ({
                              ...s,
                              truckAmount: !s.truckAmount
                                ? 1
                                : s.truckAmount + 1,
                            }))
                          }
                        >
                          <AddCircleTwoTone />
                        </IconButton>
                      </Box>
                    </LabelWrapper>
                    <LabelWrapper label="LKW-Art">
                      <ToggleButtonGroup
                        size="small"
                        orientation="horizontal"
                        exclusive
                        value={state.truckType}
                        onChange={(_, truckType) =>
                          truckType &&
                          truckType !== state.truckType &&
                          setState((s) => ({
                            ...s,
                            truckType,
                          }))
                        }
                      >
                        <ToggleButton value="XX">egal</ToggleButton>
                        <ToggleButton value="SA">Sattel</ToggleButton>
                        <ToggleButton value="3A">3-Achser</ToggleButton>
                        <ToggleButton value="4A">4-Achser</ToggleButton>
                      </ToggleButtonGroup>
                    </LabelWrapper>
                    <LabelWrapper label="Thermo">
                      <ToggleButtonGroup
                        size="small"
                        orientation="horizontal"
                        exclusive
                        value={state.thermo}
                        onChange={(_, thermo) =>
                          setState((s) => ({
                            ...s,
                            thermo,
                          }))
                        }
                      >
                        <ToggleButton value={false}>Nein</ToggleButton>
                        <ToggleButton value={true}>Ja</ToggleButton>
                      </ToggleButtonGroup>
                    </LabelWrapper>
                    
                    <LabelWrapper label="Takt">
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                          disabled={state.takt < 1}
                          onClick={() =>
                            setState((s) => ({
                              ...s,
                              takt: s.takt - 5,
                            }))
                          }
                        >
                          <RemoveCircleTwoTone />
                        </IconButton>
                        <Box sx={{ mx: "10px" }}>
                          {state.takt > 0
                            ? state.takt +
                              " min Abstand zwischen den einzelnen Beladungen"
                            : "alle gleichzeitig"}
                        </Box>
                        <IconButton
                          onClick={() =>
                            setState((s) => ({
                              ...s,
                              takt: s.takt + 5,
                            }))
                          }
                        >
                          <AddCircleTwoTone />
                        </IconButton>
                      </Box>
                    </LabelWrapper>
                  </AccordionDetails>
                </Accordion>
                
                <LabelWrapper label="Weitere Informationen">
                  <TextField
                    size="small"
                    fullWidth
                    value={state.comment}
                    placeholder="Weitere Informationen"
                    multiline
                    onChange={(e) =>
                      setState((s) => ({
                        ...s,
                        comment: (e.target as HTMLInputElement).value,
                      }))
                    }
                  />
                </LabelWrapper>
              </Fragment>
            ) : null}
          </DialogContent>
          <DialogActions sx={{ borderTop: "1px solid #aaa" }}>
            <Button onClick={close}>Abbrechen</Button>
            <Button
              variant="outlined"
              color="primary"
              disabled={!valid}
              onClick={() => {
                save(state);
                close();
              }}
            >
              Speichern
            </Button>
          </DialogActions>
        </Fragment>
      </Dialog>
    </Fragment>
  );
}
