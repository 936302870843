import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import {
  ButtonGroup,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import DeleteButton from "../dialogs/DeleteButton";
import { debounce } from "@mui/material/utils";
import { Search } from "@mui/icons-material";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

interface HeadCell {
  id: string;
  label: string;
  numeric: boolean;
  sortable: boolean;
}

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell[];
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, headCells, onRequestSort } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              sx={{ fontWeight: "bold" }}
              onClick={
                headCell.sortable ? createSortHandler(headCell.id) : undefined
              }
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="right" />
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable({
  extraButtons,
  initialSort,
  initialRows,
  rows,
  noEmptyCells = false,
  edit = "edit",
  filter = false,
  rowFormatter = (x) => x,
  headCells,
  type,
  sx = {},
  initialSortDir = "asc",
  EditIcon = EditTwoToneIcon,
  editUrl = false,
  buttons,
}: {
  extraButtons?: any;
  initialSortDir: "asc" | "desc";
  EditIcon: any;
  noEmptyCells: boolean;
  editUrl: false | ((row: any) => void);
  edit: "edit" | "karte";
  initialSort: string;
  filter?: boolean | string;
  rowFormatter: (row: any) => any;
  rows: any[];
  initialRows?: number;
  headCells: HeadCell[];
  type: string;
  buttons?: (row: any) => JSX.Element;
}) {
  const navigate = useNavigate();
  const [order, setOrder] = React.useState<Order>(initialSortDir);
  const [orderBy, setOrderBy] = React.useState<string>(initialSort);
  const [page, setPage] = React.useState(0);
  const [searchString, setSearchString] = React.useState("");
  const [searchInput, setSearchInput] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(
    !!initialRows ? initialRows : 100
  );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSearchChange = React.useMemo(
    () =>
      debounce((val) => {
        setSearchString(val);
        setPage(0);
      }, 500),
    []
  );

  const handleSearchInput = (e) => {
    console.log("input");
    const val = (e.target as HTMLInputElement).value;
    setSearchInput(val);
    handleSearchChange(val);
  };

  React.useEffect(() => {
    if (filter === false || filter === true) return;
    handleSearchChange(filter);
  }, [filter]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const search = React.useMemo(() => {
    if (filter === false || !searchString.length) return (_) => true;

    const searchTerms = searchString
      .split(" ")
      .map((x) => x.toLocaleLowerCase().trim().replace(/_/g, " "))
      .filter((x) => !!x.length);
    if (!searchTerms.length) return (_) => true;
    return (row) => {
      for (let term of searchTerms) {
        let found = false;
        for (let headCell of headCells) {
          if (
            textContent(
              "string_" + headCell.id in row
                ? row["string_" + headCell.id]
                : row[headCell.id]
            )
              .toLocaleLowerCase()
              .includes(term)
          ) {
            found = true;
            break;
          }
        }
        if (!found) return false;
      }
      return true;
    };
  }, [searchString, headCells]);

  function textContent(elem: React.ReactElement | string): string {
    if (!elem) {
      return "";
    }
    if (typeof elem === "string") {
      return elem;
    }
    const children = elem.props && elem.props.children;
    if (children instanceof Array) {
      return children.map(textContent).join("");
    }
    return textContent(children);
  }

  const filteredRows = rows.slice().map(rowFormatter).filter(search);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredRows.length) : 0;

  const Buttons = buttons;

  return (
    <Box sx={sx}>
      {filter === true ? (
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          size="medium"
          fullWidth
          sx={{ p: 1 }}
          variant="standard"
          placeholder="Filtern..."
          value={searchInput}
          onChange={handleSearchInput}
        />
      ) : null}
      <TableContainer>
        <Table aria-labelledby="tableTitle" size={"small"}>
          <EnhancedTableHead
            order={order}
            headCells={headCells}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {filteredRows
              .sort(getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow tabIndex={-1} key={row.id} className={row._className ?? ""}>
                    {headCells.map((col) => (
                      <TableCell
                        key={col.id}
                        align={col.numeric ? "right" : "left"}
                      >
                        {"string_" + col.id in row
                          ? row["string_" + col.id]
                          : row[col.id]}
                      </TableCell>
                    ))}
                    <TableCell align="right">
                      {!!Buttons ? (
                        <Buttons {...row} />
                      ) : (
                        <ButtonGroup>
                          <IconButton
                            aria-label="edit"
                            onClick={() =>
                              editUrl === false
                                ? navigate(
                                    "/" + edit + "/" + type + "s/" + row.id
                                  )
                                : editUrl(row)
                            }
                          >
                            <EditIcon />
                          </IconButton>
                          <DeleteButton type={type} id={row.id} />
                        </ButtonGroup>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            {!noEmptyCells && emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={headCells.length + 1} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component={({ children }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ padding: "0px 8px" }}>
              {!!extraButtons ? extraButtons : null}
            </div>
            <div style={{ flex: 1 }}>{children}</div>
          </div>
        )}
        count={filteredRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
