import { Add, PostAdd, Error as ErrorIcon } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Fragment, useCallback, useMemo, useState } from "react";
import LabelWrapper from "../../root/LabelWrapper";
import { StoffstromOrder } from "../Project";
import ManyToOneSelect from "@/shared/forms/ManyToOneSelect";
import { useDropzone } from "react-dropzone";

const baseStyle = {
  flex: 1,
  marginTop: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  backgroundColor: "#eaeaea",
};

const acceptStyle = {
  backgroundColor: "rgb(223, 255, 205)",
};

const rejectStyle = {
  backgroundColor: "rgb(255, 205, 205)",
};

export default function AddStoffstromDialog({
  save,
  OpenButton,
  initialState,
}: {
  save: (order: StoffstromOrder) => void;
  OpenButton: any;
  initialState: StoffstromOrder;
}) {

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [open, setOpen] = useState(false);

  const [comment, setComment] = useState("");
  const [amount, setAmount] = useState("");
  const [category, setCategory] = useState(null);
  const [date, setDate] = useState("");
  const [id, setId] = useState(0);
  const [type, setType] = useState<StoffstromOrder["type"]>("OFFER");

  const close = useCallback(() => {
    setOpen(false);
  }, []);

  const onDrop = () => {};

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept: { "*": [] } });

  const openAndSet = useCallback(() => {
    setAmount(!initialState ? "" : initialState.amount);
    setId(!initialState ? 0 : initialState.id);
    setCategory(!initialState ? null : initialState.category);
    setComment(!initialState ? "" : initialState.comment);
    setDate(!initialState ? "" : initialState.date);
    setType(!initialState ? "OFFER" : initialState.type);
    setOpen(true);
  }, [initialState]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <Fragment>
      <OpenButton onClick={openAndSet} />
      <Dialog open={open} maxWidth="lg" onClose={close}>
        <Fragment>
          <DialogTitle
            sx={{
              p: 1,
              px: 2,
              background: "#002169",
              color: "white",
              borderBottom: "1px solid rgba(255,255,255,0.5)",
            }}
          >
            Stoffstromeintrag {id > 0 ? "bearbeiten" : "hinzufügen"}
          </DialogTitle>
          <DialogContent
            sx={{
              p: 0,
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <LabelWrapper label="Typ">
              <ToggleButtonGroup
                orientation="horizontal"
                exclusive
                value={type}
                onChange={(_, val) => val && setType(val)}
              >
                <ToggleButton value="OFFER">Verfügbares Material</ToggleButton>
                <ToggleButton value="REQUEST">Benötigtes Material</ToggleButton>
              </ToggleButtonGroup>
            </LabelWrapper>
            <LabelWrapper label="Kategorie">
              <ManyToOneSelect<{ id: number; name: string }>
                size="small"
                entity={"stoffstromCategory"}
                showWithoutChoice
                autoSelect={false}
                displayFunction={(o) => (!!o ? o.name : "(unbekannt)")}
                currentId={category ?? 0}
                setId={(id) => setCategory(id || null)}
                fullWidth
                placeholder={"Kategorie"}
              />
            </LabelWrapper>
            <LabelWrapper label="Menge">
              <TextField
                size="small"
                fullWidth
                value={amount}
                placeholder="Menge"
                onChange={(e) =>
                  setAmount((e.target as HTMLInputElement).value)
                }
              />
            </LabelWrapper>

            <LabelWrapper label="Zeitraum">
              <TextField
                size="small"
                fullWidth
                value={date}
                placeholder="Zeitraum"
                onChange={(e) => setDate((e.target as HTMLInputElement).value)}
              />
            </LabelWrapper>

            <LabelWrapper label="Bilder/Dokumente">
            <Paper variant="outlined" {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {isError ? (
                  <ErrorIcon color="error" />
                ) : isLoading ? (
                  <CircularProgress color="info" />
                ) : (
                  <Box sx={{ textAlign: "center", color: "#444" }}>
                    <PostAdd />
                    <Box>Dateien hier ablegen</Box>
                  </Box>
                )}
              </Paper>
            </LabelWrapper>

            <LabelWrapper label="Weitere Informationen">
              <TextField
                size="small"
                fullWidth
                value={comment}
                placeholder="Weitere Informationen"
                multiline
                onChange={(e) =>
                  setComment((e.target as HTMLInputElement).value)
                }
              />
            </LabelWrapper>
          </DialogContent>
          <DialogActions sx={{ borderTop: "1px solid #aaa" }}>
            <Button onClick={close}>Abbrechen</Button>
            <Button
              variant="outlined"
              color="primary"
              disabled={!category || date.length === 0 || amount.length === 0}
              onClick={() => {
                save({
                  id,
                  deleted: false,
                  comment,
                  type,
                  category,
                  amount,
                  date,
                });
                close();
              }}
            >
              Speichern
            </Button>
          </DialogActions>
        </Fragment>
      </Dialog>
    </Fragment>
  );
}
