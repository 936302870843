interface SimpleType {
  type: "string" | "boolean" | "tableOnly";
}

interface ManyToOneType {
  type: "ManyToOne";
  relation: string;
  regionalized: boolean;
}

interface ManyToManyType {
  type: "ManyToMany";
  relation: string | ((object: any) => string);
  regionalized: boolean;
  remoteFilter?: any;
  groupBy?: (object: any) => string;
}

interface EnumType {
  type: "enum";
  values: {
    [key: string]: string;
  };
}

type FieldType = SimpleType | EnumType | ManyToOneType | ManyToManyType;

type Field = {
  id: string;
  title: string;
  table: boolean;
  default: any;
  disableOnEdit?: boolean;
} & FieldType;

export interface SingleDefinition {
  title: string;
  deleteable: boolean;
  sortBy: string;
  regionalized: boolean;
  createable: boolean;
  fields: Field[];
}

interface Definition {
  [id: string]: SingleDefinition;
}

export const displayFunctions = {
  staffMember: (x) => !x ? "" : (x.name + ", " + x.firstName + " [" + x.number + "]"),
  resource: (x) => !x ? "" : (x.name + " [" + x.number + "]"),
};

const def: Definition = {
  resourceTag: {
    title: "Kategorien",
    regionalized: false,
    deleteable: true,
    createable: true,
    sortBy: "_type",
    fields: [
      {
        id: "name",
        type: "string",
        title: "Name",
        table: true,
        default: "",
      },
      {
        id: "type",
        type: "enum",
        disableOnEdit: true,
        values: {
          HUMAN: "Mitarbeiter",
          CREW: "Kolonnen",
          RESOURCE: "Geräte",
        },
        title: "Typ",
        table: true,
        default: "RESOURCE",
      },
      {
        id: "calendarType",
        title: "Buchungsart",
        table: true,
        type: "enum",
        default: "NONE",
        values: {
          NONE: "Ausgeblendet",
          CALENDAR_ONLY: "Nur im Kalender",
          REQUESTABLE: "Bestellbar",
          WISHABLE: "Mit Wunsch bestellbar"
        },
      },
      {
        id: "members",
        title: "Bestandteile",
        table: false,
        type: "ManyToMany",
        regionalized: true,
        relation: ({ type }: { type: "HUMAN" | "CREW" | "RESOURCE" }) =>
          type === "HUMAN"
            ? "staffMember"
            : type === "CREW"
            ? "crew"
            : "resource",
        default: [],
      },
    ],
  },
  crew: {
    title: "Kolonnen",
    deleteable: true,
    createable: true,
    regionalized: true,
    sortBy: "name",
    fields: [
      {
        id: "name",
        title: "Name",
        table: true,
        type: "string",
        default: "",
      },
      {
        id: "leader",
        title: "Polier",
        table: false,
        type: "ManyToOne",
        relation: "staffMember",
        regionalized: true,
        default: null,
      },
      {
        id: "members",
        title: "Mitarbeiter",
        table: false,
        type: "ManyToMany",
        regionalized: true,
        relation: "staffMember",
        default: [],
      },
      {
        id: "resources",
        title: "Geräte",
        table: false,
        type: "ManyToMany",
        regionalized: true,
        relation: "resource",
        default: [],
      },
      {
        id: "tags",
        title: "Kategorien",
        table: true,
        type: "ManyToMany",
        regionalized: false,
        remoteFilter: { type: "CREW" },
        relation: "resourceTag",
        default: [],
      },
    ],
  },
  resource: {
    title: "Geräte",
    deleteable: false,
    createable: false,
    regionalized: true,
    sortBy: "name",
    fields: [
      {
        id: "name",
        title: "Name",
        table: true,
        type: "string",
        disableOnEdit: true,
        default: "",
      },
      {
        id: "number",
        title: "Nummer",
        table: true,
        disableOnEdit: true,
        type: "string",
        default: "",
      },
      {
        id: "tags",
        title: "Kategorien",
        table: true,
        type: "ManyToMany",
        regionalized: false,
        relation: "resourceTag",
        remoteFilter: { type: "RESOURCE" },
        default: [],
      },
    ],
  },
  staffMember: {
    title: "Mitarbeiter",
    deleteable: false,
    createable: false,
    regionalized: true,
    sortBy: "name",
    fields: [
      {
        id: "name",
        title: "Name",
        table: true,
        type: "string",
        disableOnEdit: true,
        default: "",
      },
      {
        id: "firstName",
        title: "Vorname",
        table: true,
        type: "string",
        disableOnEdit: true,
        default: "",
      },
      {
        id: "number",
        title: "Nummer",
        table: true,
        type: "string",
        disableOnEdit: true,
        default: "",
      },
      {
        id: "tags",
        title: "Kategorien",
        table: true,
        type: "ManyToMany",
        regionalized: false,
        relation: "resourceTag",
        remoteFilter: { type: "HUMAN" },
        default: [],
      },
    ],
  },
  supplier: {
    title: "Lieferanten",
    deleteable: false,
    createable: false,
    regionalized: false,
    sortBy: "name",
    fields: [
      {
        id: "name",
        title: "Name",
        table: true,
        type: "string",
        default: "",
      },
      {
        id: "number",
        title: "Nummer",
        table: true,
        type: "string",
        default: "",
      },
      {
        id: "contact",
        title: "Kontakt",
        table: false,
        type: "string",
        default: "",
      },
      {
        id: "phone",
        title: "Telefon",
        table: false,
        type: "string",
        default: "",
      },
      {
        id: "email",
        title: "E-Mail",
        table: false,
        type: "string",
        default: "",
      },
    ],
  },
  plant: {
    title: "Lieferwerke",
    deleteable: false,
    createable: false,
    regionalized: false,
    sortBy: "name",
    fields: [
      {
        id: "name",
        title: "Name",
        table: true,
        type: "string",
        default: "",
      },
      {
        id: "shortName",
        title: "Kurzname",
        table: true,
        type: "string",
        default: "",
      },
      {
        id: "number",
        title: "Nummer",
        table: true,
        type: "string",
        default: "",
      },
      {
        id: "supplierName",
        title: "Lieferant",
        table: true,
        type: "tableOnly",
        default: "",
      },
      {
        id: "contact",
        title: "Kontakt",
        table: false,
        type: "string",
        default: "",
      },
      {
        id: "phone",
        title: "Telefon",
        table: false,
        type: "string",
        default: "",
      },
      {
        id: "email",
        title: "E-Mail",
        table: false,
        type: "string",
        default: "",
      },
    ],
  },
  client: {
    title: "Auftraggeber",
    deleteable: false,
    createable: false,
    regionalized: true,
    sortBy: "name",
    fields: [
      {
        id: "name",
        title: "Name",
        table: true,
        type: "string",
        disableOnEdit: true,
        default: "",
      },
      {
        id: "number",
        title: "Nummer",
        disableOnEdit: true,
        table: true,
        type: "string",
        default: "",
      },
      {
        id: "contact",
        title: "Kontakt",
        table: false,
        type: "string",
        default: "",
      },
      {
        id: "phone",
        title: "Telefon",
        table: false,
        type: "string",
        default: "",
      },
      {
        id: "email",
        title: "E-Mail",
        table: false,
        type: "string",
        default: "",
      },
    ],
  },
  subcontractor: {
    title: "Subunternehmen",
    deleteable: true,
    regionalized: false,
    createable: true,
    sortBy: "name",
    fields: [
      {
        id: "name",
        title: "Name",
        table: true,
        type: "string",
        default: "",
      },
      {
        id: "number",
        title: "Nummer",
        table: true,
        type: "string",
        default: "",
      },
      {
        id: "contact",
        title: "Kontakt",
        table: false,
        type: "string",
        default: "",
      },
      {
        id: "phone",
        title: "Telefon",
        table: false,
        type: "string",
        default: "",
      },
      {
        id: "email",
        title: "E-Mail",
        table: false,
        type: "string",
        default: "",
      },
    ],
  },
  stoffstromCategory: {
    title: "Stoffstrom-Kategorien",
    deleteable: true,
    regionalized: false,
    createable: true,
    sortBy: "name",
    fields: [
      {
        id: "name",
        title: "Name",
        table: true,
        type: "string",
        default: "",
      },
    ],
  },
  tradeTemplate: {
    title: "Gewerke",
    deleteable: true,
    regionalized: false,
    createable: true,
    sortBy: "name",
    fields: [
      {
        id: "name",
        title: "Name",
        table: true,
        type: "string",
        default: "",
      },
      {
        id: "autoOrders",
        title: "Standardbedarf",
        table: false,
        type: "ManyToMany",
        regionalized: false,
        groupBy: (x) =>
          !x ? "" : x.type === "HUMAN"
            ? "Mitarbeiter"
            : x.type === "CREW"
            ? "Kolonnen"
            : "Geräte",
        relation: "resourceTag",
        default: [],
      },
    ],
  },
  user: {
    title: "Benutzer",
    createable: true,
    deleteable: true,
    regionalized: true,
    sortBy: "name",
    fields: [
      {
        id: "name",
        title: "Name",
        table: true,
        type: "string",
        default: "",
      },
      {
        id: "firstName",
        title: "Vorname",
        table: true,
        type: "string",
        default: "",
      },
      {
        id: "email",
        title: "E-Mail",
        table: true,
        type: "string",
        default: "",
      },
    ],
  },
};

export default def;
