import { PossibleCalendarTypes } from "@/components/calendar/MainCalendar";
import BaseApiService from "@/shared/api/BaseApiService";
import request from "@/shared/api/request";

export default class ApiService extends BaseApiService {
    static parseOrderConfirmation(file: File) {
        const data = new FormData();
        data.append("file", file);
        return request(
          {
            url: "/parseOrderConfirmation/",
            method: "POST",
            headers: {
                'Content-Type': 'multipart/form-data'
              },
            data,
          }
        );
      }

      static searchMaterials() {
        return (data: {search: string}) =>
          request({
            url: "/data/searchMaterials/" + data.search,
            method: "GET",
          });
      }

      static changeCalendar() {
        return (data) =>
          request({
            url: "/data/changeCalendar",
            method: "POST",
            data
          });
      }

      static getCalendar(type: PossibleCalendarTypes, reference: number, month: string, region: number) {
        console.log("region", region);
        return () =>
          request({
            url: "/data/calendar/" + type + "/"+reference+"/"+month+"/"+region,
            method: "GET",
          });
      }

      static getMap(region: number) {
        return () =>
          request({
            url: "/data/map/"+region,
            method: "GET",
          });
      }

      static getEntitiesByTag(entity: BackendEntity, tag: number, region: number) {
        return () =>
          request({
            url: "/entityByTag/" + entity + "/"+tag+ "/"+region,
            method: "GET",
          });
      }

}
