import { Fragment } from "react/jsx-runtime";
import { CalDataLine } from "./InnerCalendar";
import {
  CalendarContext,
  CalendarElementsProps,
} from "@/components/shared/VerticalCalendar";
import { useCallback, useContext, useState } from "react";
import dayjs from "dayjs";
import { ChatBubble, Check, Delete, DoneAll, Edit, Message } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ApiService from "@/api/ApiService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

interface CalStaffMember {
  id: number;
  name: string;
  number: string;
}
interface CalCrew {
  id: number;
  name: string;
}
interface CalResource {
  id: number;
  name: string;
  number: string;
}
interface CalResourceOrder {
  id: number;
  comment: string;
  singleDayOrder: boolean;
}

interface CalResourceBooking {
  id: number;
  crew: number;
}

const Information = ({ data }) => {
  return (
    <Fragment>
      <div
        className="vc-res-status"
        style={{
          background:
            data.status === "PLANNING"
              ? "#99D4FF"
              : data.status === "RAW"
              ? "#A6FF99"
              : "#FF9999",
        }}
      >{(data?.resourceBooking?.resourceOrder?.comment ?? data?.resourceOrder?.comment ?? "").length > 0 ? <div className="messageIndicator" /> : null}</div>
      {!!data.startTime
        ? data.startTime + " "
        : !!data.firstUnload
        ? <em>{data.firstUnload} </em>
        : ""}
      {data.phase.project.name} ({data.phase.project.number})
    </Fragment>
  );
};

const calculateAddMap = (date: string, add: number, jobs: string[]) => {
  const newAddMap: { [date: string]: string } = {};
  if (add !== 0) {
    const dates = add > 0 ? jobs : jobs.slice().reverse();

    newAddMap[date] = dayjs(date, "YYYY-MM-DD")
      .add(add, "days")
      .format("YYYY-MM-DD");
    let lastDate = newAddMap[date];
    for (const c of dates) {
      if ((add > 0 && c <= date) || (add < 0 && c >= date)) continue;
      if ((add > 0 && lastDate < c) || (add < 0 && lastDate > c)) break;
      let newDate = dayjs(lastDate, "YYYY-MM-DD").add(add > 0 ? 1 : -1, "day");
      while (
        newDate.isoWeekday() > 5 &&
        !dates.includes(newDate.format("YYYY-MM-DD"))
      ) {
        newDate = newDate.add(add > 0 ? 1 : -1, "day");
      }
      newAddMap[c] = newDate.format("YYYY-MM-DD");
      lastDate = newAddMap[c];
    }
  }
  return newAddMap;
};

interface ChangeData {
  move: { phase: number; moveMap: { [origin: string]: string } } | null;
  orderToBooking: {
    order: number;
    jobId: number | null;
    target: number;
  } | null;
  bookingMove: {
    booking: number | null;
    order: number | null;
    target: number;
  } | null;
}

const blankImage = new Image();
blankImage.src =
  "data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=";

const preventDefault = (e) => e.preventDefault();

export default function ResourceLine({
  offset,
  data,
  hoverLine,
  setHoverLine,
  moveMap,
  setMoveMap,
  moveData,
  setMoveData,
  type,
}: {
  offset: number;
  type: "CREWS" | "STAFF" | "RESOURCES";
  data: CalDataLine<
    CalStaffMember | CalCrew | CalResource,
    | { resourceOrder: CalResourceOrder }
    | { resourceBooking: CalResourceBooking }
  >;
}) {
  const [startPageXY, setStartPageXY] = useState<[number, number] | null>(null);

  const view = useContext(CalendarContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const changeMutation = useMutation<any, AxiosResponse, ChangeData>({
    mutationFn: ApiService.changeCalendar(),
    onSettled: (answer) => {
      queryClient.invalidateQueries({ queryKey: ["getCalendar"] });
      setMoveData(null);
      setMoveMap(null);
      setHoverLine(null);
      setStartPageXY(null);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: ApiService.deleteEntity("resourceBooking", 0),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getCalendar"] });
    },
  });

  const dragstart = useCallback((e, element) => {
    e.dataTransfer.setDragImage(blankImage, 0, 0);
    e.dataTransfer.effectAllowed = "move";
    e.target.style.cursor = "ew-resize !important";
    e.target.classList.add("dragging");
    setMoveData({ ...element, origin: data.id });
    setStartPageXY([e.pageX, e.pageY]);
  }, []);

  const mouseMove = useCallback(
    (e) => {
      if (e.pageY === 0 || e.pageX === 0 || !startPageXY) return;
      const factor = view.zoom < 15 ? 7 : 1;
      const add =
        Math.round((e.pageX - startPageXY[0]) / view.zoom / factor) * factor;
      const addMap = calculateAddMap(
        e.target.dataset.date,
        add,
        moveData.phase.jobKeys
      );
      console.log(
        "SET ADD MAP",
        addMap,
        e.target.dataset.date,
        add,
        moveData.phase.jobKeys
      );
      setMoveMap(addMap);
    },
    [view.zoom, startPageXY, moveData]
  );

  const mouseUp = useCallback(
    (e) => {
      if (e.pageY === 0 || e.pageX === 0 || !startPageXY) return;
      const factor = view.zoom < 15 ? 7 : 1;
      const add =
        Math.round((e.pageX - startPageXY[0]) / view.zoom / factor) * factor;
      const addMap = calculateAddMap(
        e.target.dataset.date,
        add,
        moveData.phase.jobKeys
      );

      if (!!moveData.resourceOrder) {
        changeMutation.mutate({
          move: { phase: moveData.phase.id, moveMap: addMap },
          orderToBooking:
            hoverLine.id === 0
              ? null
              : {
                  order: moveData.resourceOrder.id,
                  jobId:
                    hoverLine.moveOnlyOrigin ||
                    moveData.resourceOrder.singleDayOrder
                      ? moveData.id
                      : null,
                  target: hoverLine.id,
                },
          bookingMove: null,
        });
      } else {
        changeMutation.mutate({
          move: { phase: moveData.phase.id, moveMap: addMap },
          orderToBooking: null,
          /* hoverLine.id === 0
              ? null
              : {
                  order: moveData.resourceOrder.id,
                  jobId:
                    hoverLine.moveOnlyOrigin ||
                    moveData.resourceOrder.singleDayOrder
                      ? moveData.id
                      : null,
                  target: hoverLine.id,
                }, */
          bookingMove: {
            type,
            origin: moveData.origin,
            booking: hoverLine.moveOnlyOrigin
              ? moveData.resourceBooking.id
              : null,
            order: !hoverLine.moveOnlyOrigin
              ? moveData.resourceBooking.resourceOrder.id
              : null,
            target: hoverLine.id,
          },
        });
      }

      e.target.classList.remove("dragging");
    },
    [startPageXY, view.zoom, hoverLine, moveData]
  );

  const days = [];
  const moving = [];
  for (let date in data.elements) {
    const exx = [];
    const dd = dayjs(date, "YYYY-MM-DD");

    if (dd < view?.calStart || dd > view?.calEnd) continue;

    for (let i = 0; i < data.elements[date].length; i++) {
      const element = data.elements[date][i];

      let changedOffset = offset;
      let changedDate = date;

      if (!!moveData && !!hoverLine) {
        changedDate =
          element.phase.id === moveData.phase.id ? moveMap[date] ?? date : date;

        if (!!moveData.resourceOrder !== !!element.resourceOrder)
          changedOffset = offset;
        else if (!!moveData.resourceOrder) {
          if (
            hoverLine.moveOnlyOrigin ||
            moveData.resourceOrder.singleDayOrder
          ) {
            changedOffset =
              moveData.id === element.id ? hoverLine.offset : offset;
          } else {
            changedOffset =
              moveData.resourceOrder.id === element.resourceOrder.id
                ? hoverLine.offset
                : offset;
          }
        } else if (hoverLine.moveOnlyOrigin) {
          changedOffset =
            moveData.resourceBooking.id === element.resourceBooking.id
              ? hoverLine.offset
              : offset;
        } else {
          changedOffset =
            moveData.resourceBooking.resourceOrder.id ===
              element.resourceBooking.resourceOrder.id &&
            moveData.origin === data.id
              ? hoverLine.offset
              : offset;
        }
      }

      let hide = 0;

      if (changedDate !== date || changedOffset !== offset) {
        const xdd = dayjs(changedDate, "YYYY-MM-DD");
        hide = moveData.id !== element.id ? 1 : 2;

        if (xdd >= view?.calStart && xdd <= view?.calEnd) {
          const weekday = ((parseInt(xdd.format("d")) + 6) % 7) + 1;

          moving.push(
            <div
              className={
                "vc-date-res moving" +
                (moveData.id === element.id ? " moveOrigin" : "") +
                " d" +
                weekday
              }
              key={"phasemove" + data.id + "_" + date + "_" + i}
              style={{
                gridRow: changedOffset,
                gridColumn: xdd.diff(view.calStart, "days") + 2,
              }}
            >
              <div
                className={
                  "resourceOrder" +
                  (!!element.resourceBooking ? " resourceBooking" : "")
                }
              >
                <Information data={element} />
              </div>
            </div>
          );
        }
      }

      exx.push(
        <div
          draggable={true}
          onDragOver={preventDefault}
          onDragStart={(e) => dragstart(e, element)}
          onDrag={mouseMove}
          data-date={date}
          onDragEnd={mouseUp}
          className={
            "resourceOrder" +
            (hide > 0 ? " moveX" + hide : "") +
            (!!element.resourceBooking ? " resourceBooking" : "")
          }
          key={"phase" + data.id + "_" + date + "_" + i}
        >
          <Information data={element} />
          <div className="resOrderEdit">
            {!!element.resourceBooking ? (
              <div
                className="resOrderButton red"
                onClick={() =>
                  deleteMutation.mutate(element.resourceBooking.id)
                }
              >
                <Delete fontSize="inherit" />
              </div>
            ) : null}
            {data.id !== 0 && !!element.resourceOrder ? (
              <div
                className="resOrderButton green"
                onClick={() =>
                  changeMutation.mutate({
                    move: null,
                    orderToBooking: {
                      order: element.resourceOrder.id,
                      jobId: element.id,
                      target: data.id,
                    },
                    bookingMove: null,
                  })
                }
              >
                <Check fontSize="inherit" />
              </div>
            ) : (
              <div />
            )}
            {data.id !== 0 &&
            !!element.resourceOrder &&
            !element.resourceOrder.singleDayOrder ? (
              <div
                onClick={() =>
                  changeMutation.mutate({
                    move: null,
                    orderToBooking: {
                      order: element.resourceOrder.id,
                      jobId: null,
                      target: data.id,
                    },
                    bookingMove: null,
                  })
                }
                className="resOrderButton green"
              >
                <DoneAll fontSize="inherit" />
              </div>
            ) : null}
            <div className="resOrderButton">
              <Edit
                onClick={() => navigate("/project/" + element.phase.project.id, {state: {phaseId: element.phase.id, date}})}
                fontSize="inherit"
              />
            </div>
          </div>
        </div>
      );
    }

    days.push(
      <div
        className={
          "vc-date-res boxWrapper" + (data.id === 0 ? " openline" : "")
        }
        data-date={date}
        key={"phase" + data.id + "_" + date}
        style={{
          gridRow: offset,
          gridColumn: dd.diff(view.calStart, "days") + 2,
        }}
      >
        {exx}
      </div>
    );
  }

  return (
    <Fragment>
      <div
        key={"sidebar" + offset}
        className={"vc-sidebar calendar" + (data.id === 0 ? " openline" : "")}
        style={{
          gridRow: offset,
        }}
      >
        {data.id === 0 ? (
          <em>offen</em>
        ) : (
          (!!data?.number ? data.number + ": " : "") + data.name
        )}
      </div>

      {days}
      {moving}
      <div
        className={"vc-hl" + (data.id === 0 ? " openline" : "")}
        onDragOver={(e) =>
          setHoverLine({ id: data.id, offset, moveOnlyOrigin: e.shiftKey })
        }
        key={"hl" + offset}
        style={{
          pointerEvents: "initial",
          gridRow: offset,
          gridColumn: 2 + " / " + (view.calEnd.diff(view.calStart, "days") + 2),
        }}
      ></div>
    </Fragment>
  );
}
