import dayjs from "dayjs";
import { initialJob, ProjectJob, ProjectPhase, ProjectState } from "../Project";
import { CalendarContext, CalendarElementsProps } from "../../shared/VerticalCalendar";
import { Fragment } from "react/jsx-runtime";
import { useState, useLayoutEffect, useMemo, useContext } from "react";
import JobLine from "./JobLine";

const blankImage = new Image();
blankImage.src =
  "data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=";

export const moveJobs = (jobs: ProjectPhase["jobs"], days: number) => {
  return Object.fromEntries(
    Object.values(jobs).map((job) => {
      const date = dayjs(job.date).add(days, "days").format("YYYY-MM-DD");
      return [date, { ...job, date }];
    })
  );
};

export default function ProjectPhaseLine({
  project,
  setField,
  line,
  phaseId,
  currentSelection,
  setCurrentSelection,
  projectStartAdd,
}: {
  project: ProjectState;
  setField: (
    index: number,
    changes: Partial<ProjectPhase>,
    isDateChange?: boolean
  ) => void;
  line: number;
  phaseId: number;
}) {
  const view = useContext(CalendarContext);
  const onDateChange = (plannedStart, plannedEnd, moveAdd) => {
    setField(
      phaseId,
      {
        plannedStart,
        plannedEnd,
        jobs: moveAdd === 0 ? phase.jobs : moveJobs(phase.jobs, moveAdd),
      },
      true
    );
    setCurrentSelection({ phase: phaseId, date: null });
  };

  const changeDates = (addMap, origin) => {
    if (Object.keys(addMap).length === 0) return;
    setField(
      phaseId,
      {
        jobs: Object.fromEntries(
          Object.values(phase.jobs).map((job) => {
            return [
              addMap[job.date] ?? job.date,
              { ...job, date: addMap[job.date] ?? job.date },
            ];
          })
        ),
      },
      true
    );
    setCurrentSelection({ phase: phaseId, date: addMap[origin] ?? origin });
  };

  const phase = project.phases[phaseId];

  const selectDate = (date: string) => {
    if (!(date in phase.jobs)) {
      setField(
        phaseId,
        {
          plannedStart:
            !phase.plannedStart || phase.plannedStart > date
              ? date
              : phase.plannedStart,
          plannedEnd:
            !phase.plannedEnd || phase.plannedEnd < date
              ? date
              : phase.plannedEnd,
          jobs: {
            ...phase.jobs,
            [date]: initialJob(date),
          },
        },
        true
      );
    }
    setCurrentSelection({ phase: phaseId, date });
  };

  const initDuration = (e) => {
    const start = view.calStart.add(
      Math.floor((e.clientX - e.target.getBoundingClientRect().left) / view.zoom),
      "days"
    );
    const end = start.add(6, "day");
    onDateChange(start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD"), 0);
  };

  const [startAdd, setStartAdd] = useState(0);
  const [moveAdd, setMoveAdd] = useState(0);
  const [endAdd, setEndAdd] = useState(0);
  const [startEvent, setStartEvent] = useState<{
    pageX: number;
    point: "start" | "end" | "move";
  } | null>(null);

  const [startMax, endMin] = useMemo(() => {
    const dates = Object.values(phase.jobs).filter(j => !j.deleted).map(x => x.date)
    if (!phase.plannedStart || !phase.plannedEnd) return [0, 0];

    const ps = dayjs(phase.plannedStart, "YYYY-MM-DD");
    const pe = dayjs(phase.plannedEnd, "YYYY-MM-DD");

    if (dates.length === 0) {
      const s = pe.diff(ps, "days");
      return [s, -s];
    } else {
      const min = dayjs(
        dates.reduce((min, c) => (c < min ? c : min)),
        "YYYY-MM-DD"
      );
      const max = dayjs(
        dates.reduce((max, c) => (c > max ? c : max)),
        "YYYY-MM-DD"
      );
      return [min.diff(ps, "days"), max.diff(pe, "days")];
    }
  }, [phase.jobs, phase.plannedEnd, phase.plannedStart]);

  useLayoutEffect(() => {
    setStartAdd(0);
    setMoveAdd(0);
    setEndAdd(0);
  }, [phase.plannedStart, phase.plannedEnd]);

  const start = !phase.plannedStart ? null : dayjs(phase.plannedStart);
  const end = !phase.plannedEnd ? null : dayjs(phase.plannedEnd);

  const dragstart = (e, point) => {
    e.dataTransfer.setDragImage(blankImage, 0, 0);
    e.dataTransfer.effectAllowed = "move";
    e.target.style.cursor = "ew-resize !important";
    setStartEvent({ pageX: e.pageX, point });
  };

  const mouseMove = (pageX: number) => {
    if (pageX === 0) return;
    const factor = view.zoom < 15 ? 7 : 1;
    if (startEvent.point === "move") {
      const v = Math.round((pageX - startEvent.pageX) / view.zoom / factor) * factor;
      setMoveAdd(v);
    } else if (startEvent.point === "start") {
      setStartAdd(
        Math.min(
          startMax,
          Math.round((pageX - startEvent.pageX) / view.zoom / factor) * factor
        )
      );
    } else {
      setEndAdd(
        Math.max(
          endMin,
          Math.round((pageX - startEvent.pageX) / view.zoom / factor) * factor
        )
      );
    }
  };

  const mouseUp = (pageX: number) => {
    if (pageX === 0) return;
    const factor = view.zoom < 15 ? 7 : 1;
    if (startEvent.point === "move") {
      const v = Math.round((pageX - startEvent.pageX) / view.zoom / factor) * factor;
      setMoveAdd(v);
    } else if (startEvent.point === "start") {
      setStartAdd(
        Math.min(
          startMax,
          Math.round((pageX - startEvent.pageX) / view.zoom / factor) * factor
        )
      );
    } else {
      setEndAdd(
        Math.max(
          endMin,
          Math.round((pageX - startEvent.pageX) / view.zoom / factor) * factor
        )
      );
    }
    onDateChange(
      start.add(startAdd + moveAdd, "days").format("YYYY-MM-DD"),
      end.add(endAdd + moveAdd, "days").format("YYYY-MM-DD"),
      moveAdd
    );
    setStartEvent(null);
  };

  const astart =
    projectStartAdd + startAdd + moveAdd === 0 || !start
      ? start
      : start.add(projectStartAdd + startAdd + moveAdd, "days");
  const aend =
    projectStartAdd + endAdd + moveAdd === 0 || !end
      ? end
      : end.add(projectStartAdd + endAdd + moveAdd, "days");

  const xstart = !astart
    ? null
    : astart > view.calEnd
    ? null
    : astart < view.calStart
    ? view.calStart
    : astart;
  const xend = !aend
    ? null
    : aend < view.calStart
    ? null
    : aend > view.calEnd
    ? view.calEnd
    : aend;

  return (
    <Fragment>
      <div
        key={"sidebar" + line}
        className={"vc-sidebar phase"}
        style={{
          gridRow: line - 1 + " / " + (line + (!phase.plannedEnd ? 0 : 1)),
          background:
            currentSelection.phase === phaseId ? "#cbdbff" : "#eaeaea",
        }}
        onClick={() => setCurrentSelection({ phase: phaseId, date: null })}
      >
        {phase.name || "Unbenannt"}
      </div>
      {!xstart || !xend ? null : (
        <div
          className={"vc-phase"}
          key={"phase" + line}
          style={{
            display: "flex",
            justifyContent: "space-between",
            gridRow: line - 1,
            gridColumn:
              xstart.diff(view.calStart, "days") +
              2 +
              " / " +
              (xend.diff(view.calStart, "days") + 3),
          }}
        >
          <div
            className="vc-reshandle left"
            draggable={true}
            onDragOver={(e) => e.preventDefault()}
            onDragStart={(e) => dragstart(e, "start")}
            onDrag={(e) => (!startEvent ? null : mouseMove(e.pageX))}
            onDragEnd={(e) => (!startEvent ? null : mouseUp(e.pageX))}
          />
          <div
            className="vc-reshandle move"
            draggable={true}
            onDragOver={(e) => e.preventDefault()}
            onDragStart={(e) => dragstart(e, "move")}
            onDrag={(e) => (!startEvent ? null : mouseMove(e.pageX))}
            onDragEnd={(e) => (!startEvent ? null : mouseUp(e.pageX))}
          />
          <div
            className="vc-reshandle right"
            draggable={true}
            onDragOver={(e) => e.preventDefault()}
            onDragStart={(e) => dragstart(e, "end")}
            onDrag={(e) => (!startEvent ? null : mouseMove(e.pageX))}
            onDragEnd={(e) => (!startEvent ? null : mouseUp(e.pageX))}
          />
        </div>
      )}
      <div
        className={"vc-hl" + (!phase.plannedEnd ? "" : " slight")}
        key={"hl2" + line}
        onClick={(e) => initDuration(e)}
        style={{
          pointerEvents: !phase.plannedEnd ? "initial !important" : "none",
          gridRow: line - 1,
          gridColumn: 2 + " / " + (view.calEnd.diff(view.calStart, "days") + 2),
        }}
      ></div>
      {!phase.plannedEnd ? null : (
        <JobLine
        selectedDate={currentSelection.phase === phaseId ? (currentSelection.date ?? "") : ""}
          changeDates={changeDates}
          startAdd={projectStartAdd + moveAdd}
          key={"jobline" + line}
          line={line}
          onSelect={selectDate}
          jobs={phase.jobs}
        />
      )}
    </Fragment>
  );
}
