import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./assets/styles/index.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { deDE } from "@mui/material/locale";
import * as dayjs from "dayjs";
import relativeTime from "dayjs/esm/plugin/relativeTime";
import isoWeek from "dayjs/esm/plugin/isoWeek";
import advancedFormat from "dayjs/esm/plugin/advancedFormat";
import "dayjs/locale/de";
import "leaflet/dist/leaflet.css";
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
//import { DndProvider } from "react-dnd";
//import { HTML5Backend } from "react-dnd-html5-backend";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://751420d58204dd92e21484dae9208554@o4508116228308992.ingest.de.sentry.io/4508116233027664",
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
});


const DndProvider = React.Fragment;

dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(isoWeek);

dayjs.locale("de");

const theme = createTheme(
  {
    palette: {
      primary: {
        light: "#414898",
        main: "#002169",
        dark: "#00003e",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ffe97d",
        main: "#ffb74d",
        dark: "#c88719",
        contrastText: "#000",
      },
    },
  },
  deDE
);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <DndProvider /* backend={HTML5Backend} */>
        <App />
      </DndProvider>
    </ThemeProvider>
  </React.StrictMode>
);

postMessage({ payload: "removeLoading" }, "*");
