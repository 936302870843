import { Paper, Button, Box, IconButton, Alert, AlertTitle } from "@mui/material";
import { Fragment, useCallback } from "react";
import { Add, Delete, Edit } from "@mui/icons-material";
import { initialMaterialOrder, MaterialOrder, SupplierOrder } from "../Project";
import EnhancedTable from "@/shared/views/EnhancedTable";
import { TopBar } from "@/shared/structure/TopBar";
import AddMaterialDialog from "../dialogs/AddMaterialDialog";

export const createDeliveryText = (truckAmount: MaterialOrder["truckAmount"], truckType: MaterialOrder["truckType"], thermo: MaterialOrder["thermo"], takt: MaterialOrder["takt"]) => {
  let truckTypeStr = truckType === "3A" ? "3-Achser" : truckType === "SA" ? "Sattel" : truckType === "4A" ? "4-Achser" : "";
  if (thermo) {
    truckTypeStr = (truckTypeStr || "LKW")+" (Thermo)";
  }
  const truckPart = !truckAmount ? truckTypeStr : truckAmount+" "+(truckTypeStr || "LKW");
  const taktPart = takt > 0 ? "Takt: "+takt+" min." : "";
  return truckPart+(truckPart.length > 0 && taktPart.length > 0 ? ", ": "")+taktPart;
};

const headCells = [
  {
    id: "_time",
    label: "Uhrzeit",
    numeric: true,
    sortable: true,
  },
  {
    id: "_lieferant",
    label: "Lieferant",
    numeric: false,
    sortable: true,
  },
  {
    id: "_artikel",
    label: "Artikel",
    numeric: false,
    sortable: true,
  },
  {
    id: "amount",
    label: "Menge",
    numeric: true,
    sortable: true,
  },
  {
    id: "_delivery",
    label: "Logistik",
    numeric: false,
    sortable: true,
  },
  {
    id: "_comment",
    label: "Weitere Informationen",
    numeric: false,
    sortable: true,
  },
];

export default function MaterialTab({
  supplierOrders,
  jobState = [],
  setJobState = (_e) => {},
}: {
  supplierOrders: SupplierOrder[],
  jobState: MaterialOrder[];
  setJobState: (data: MaterialOrder[]) => void;
}) {
  const deleteJobOrder = useCallback(
    (index: number) => {
      setJobState(
        jobState.slice().map((so, i) => ({
          ...so,
          deleted: !!so.deleted || i === index,
        }))
      );
    },
    [jobState]
  );

  const saveJobOrder = useCallback(
    (subOrder: MaterialOrder, index: number) => {
      if (index === -1) {
        const newState = jobState.slice();
        newState.push(subOrder);
        setJobState(newState);
      } else {
        setJobState(
          jobState.slice().map((so, i) => (i === index ? subOrder : so))
        );
      }
    },
    [jobState]
  );

  const hasJobOrders = jobState.filter((x) => !x.deleted).length > 0;

  const rowFormatter = useCallback(
    (row: MaterialOrder) => ({
      ...row,
      _time: row.firstUnload ?? "egal",
      _lieferant: row.article?.plantName +" ("+row.article?.supplierName+")",
      _artikel: row.article?.name+" ("+row.article?.number+")",
      amount: row.amount,
      _delivery: createDeliveryText(row.truckAmount,row.truckType,row.thermo,row.takt),
      _comment: (row.comment+(!row.position ? ((row.comment.length > 0 ? ", " : "")+"Unbepreister Artikel!") : "")) || "-",
    }),
    []
  );

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
      }}
    >
      <TopBar>
        <AddMaterialDialog
          save={(o) => saveJobOrder(o, -1)}
          supplierOrders={supplierOrders}
          OpenButton={({ onClick }) => (
            <Button startIcon={<Add />} onClick={onClick}>
              Bestellung hinzufügen
            </Button>
          )}
          initialState={initialMaterialOrder()}
        />
      </TopBar>
      <Box
        sx={{ flex: 1, overflow: "auto", background: "#f5f5f5", padding: 2 }}
      >
        {hasJobOrders ? (
          <Paper>
            <EnhancedTable
              initialRows={100}
              buttons={(row) => (
                <Fragment>
                  <AddMaterialDialog
                   supplierOrders={supplierOrders}
                    save={(order) => saveJobOrder(order, row.index)}
                    initialState={jobState[row.index]}
                    OpenButton={({ onClick }) => (
                      <IconButton size="small" onClick={onClick}>
                        <Edit />
                      </IconButton>
                    )}
                  />
                  <IconButton
                    size="small"
                    onClick={() => deleteJobOrder(row.index)}
                  >
                    <Delete />
                  </IconButton>
                </Fragment>
              )}
              type=""
              noEmptyCells
              initialSort="_time"
              headCells={headCells}
              rowFormatter={rowFormatter}
              rows={jobState
                .map((x, index) => ({ ...x, index }))
                .filter((x) => !x.deleted)}
            />
          </Paper>
        ) : <Alert severity="info" variant="filled">
          <AlertTitle>Nichts gefunden.</AlertTitle>
          Hier wurde noch nichts bestellt.
        </Alert>}
      </Box>
    </Box>
  );
}
