import ApiService from "@/api/ApiService";
import { PostAdd, Error as ErrorIcon, Add, Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { Fragment, useCallback, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import LabelWrapper from "../../root/LabelWrapper";
import PortalDateRangePicker from "../elements/PortalDateRangePicker";
import { uniqueId } from "lodash";

const baseStyle = {
  flex: 1,
  marginTop: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  backgroundColor: "#eaeaea",
};

const acceptStyle = {
  backgroundColor: "rgb(223, 255, 205)",
};

const rejectStyle = {
  backgroundColor: "rgb(255, 205, 205)",
};

interface ParsedArticle {
  article_number: string;
  article_name: string;
  delivery_type: string;
  price: string;
  amount: string;
  plant: string;
  id: string;
  deleted: boolean;
}

export interface ParsedState {
  supplier_name: string;
  valid_from: string | null;
  valid_until: string | null;
  document_number: string;
  articles: ParsedArticle[];
}

export default function OrderConfirmationParser({save}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [open, setOpen] = useState(false);
  const [parsedState, setParsedState] = useState<ParsedState | null>(null);

  const addPosition = () => {
    if (!parsedState) return;
    const articles = parsedState.articles.filter((t) => !t.deleted);
    articles.push({
      delivery_type: "",
      article_number: "",
      article_name: "",
      price: "",
      amount: "",
      plant: "",
      id: uniqueId(),
      deleted: false,
    });
    setParsedState({
      ...parsedState,
      articles,
    });
  };

  const deletePosition = (uuid: string) => {
    if (!parsedState) return;
    setParsedState({
      ...parsedState,
      articles: parsedState.articles.map((t) => ({
        ...t,
        deleted: t.deleted || t.id === uuid,
      })),
    });
  };

  const setPositionField = (
    uuid: string,
    key: keyof ParsedArticle,
    value: string
  ) => {
    if (!parsedState) return;
    setParsedState({
      ...parsedState,
      articles: parsedState.articles.map((t) => ({
        ...t,
        [key]: t.id === uuid ? value : t[key],
      })),
    });
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    // Do something with the files
    setIsLoading(true);
    try {
      const result = await ApiService.parseOrderConfirmation(acceptedFiles[0]);
      if (!("supplier_name" in result)) {
        throw "Invalid";
      }
      result.articles = result.articles.map((a: ParsedArticle) => ({
        article_number: !!a.article_number ? a.article_number : "",
        article_name: !!a.article_name ? a.article_name : "",
        delivery_type: !!a.delivery_type ? a.delivery_type : "",
        price:
          !!a.price &&
          (typeof a.price == "number" || !isNaN(parseFloat(a.price)))
            ? parseFloat(a.price).toFixed(2).replace(/\./, ",")
            : "",
        amount:
          !!a.amount &&
          (typeof a.amount == "number" || !isNaN(parseInt(a.amount)))
            ? a.amount + ""
            : "",
        plant: !!a.plant ? a.plant : "",
        id: uniqueId(),
        deleted: false,
      }));
      setParsedState(result);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setIsError(true);
      await new Promise((resolve) => setTimeout(resolve, 3000));
      setIsError(false);
    }
  }, []);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept: { "application/pdf": [] } });

  const close = useCallback(() => {
    setOpen(false);
    setParsedState(null);
  }, []);

  const setField = (changes: Partial<ParsedState>) => {
    if (!parsedState) return;
    const newState = { ...parsedState, ...changes };
    setParsedState(newState);
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const valid = !!parsedState && parsedState.supplier_name !== "" && parsedState.document_number !== "" && !!parsedState.valid_from && !!parsedState.valid_until;

  return (
    <Fragment>
      <Button startIcon={<Add />} onClick={() => setOpen(true)}>
        AB hinzufügen
      </Button>
      <Dialog open={open} maxWidth="lg" onClose={close}>
        {!!parsedState ? (
          <Fragment>
            <DialogTitle
              sx={{
                p: 1,
                px: 2,
                background: "#002169",
                color: "white",
                borderBottom: "1px solid rgba(255,255,255,0.5)",
              }}
            >
              Auftragsbestätigung: Manuelle Kontrolle
            </DialogTitle>
            <DialogContent
              sx={{
                p: 0,
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box>
                <LabelWrapper label="Lieferant">
                  <TextField
                    size="small"
                    fullWidth
                    value={parsedState.supplier_name}
                    placeholder="Lieferant"
                    onChange={(e) =>
                      setField({
                        supplier_name: (e.target as HTMLInputElement).value,
                      })
                    }
                  />
                </LabelWrapper>

                <LabelWrapper label="Auftragsnummer">
                  <TextField
                    size="small"
                    fullWidth
                    value={parsedState.document_number}
                    placeholder="Auftragsnummer"
                    onChange={(e) =>
                      setField({
                        document_number: (e.target as HTMLInputElement).value,
                      })
                    }
                  />
                </LabelWrapper>
                <LabelWrapper label="Gültigkeitszeitraum">
                  <PortalDateRangePicker
                    small
                    onChange={([startDate, endDate]) =>
                      setField({
                        valid_from: !!startDate
                          ? dayjs(startDate).format("YYYY-MM-DD")
                          : null,
                        valid_until: !!endDate
                          ? dayjs(endDate).format("YYYY-MM-DD")
                          : null,
                      })
                    }
                    value={[
                      !!parsedState.valid_from
                        ? dayjs(parsedState.valid_from).toDate()
                        : null,
                      !!parsedState.valid_until
                        ? dayjs(parsedState.valid_until).toDate()
                        : null,
                    ]}
                  />
                </LabelWrapper>
              </Box>
              <TableContainer sx={{ flex: 1 }}>
                <Table size="small" stickyHeader>
                  <TableHead sx={{ background: "#eee" }}>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Artikelnummer
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Artikelname
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Lieferwerk
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Zufuhrart
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Menge</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Preis</TableCell>
                      <TableCell sx={{}}>
                        <IconButton size="small" onClick={() => addPosition()}>
                          <Add />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {parsedState.articles
                      .filter((x) => !x.deleted)
                      .map((x) => (
                        <TableRow>
                          <TableCell align="right">
                            <TextField
                              size="small"
                              fullWidth
                              variant="outlined"
                              value={x.article_number}
                              onChange={(e) =>
                                setPositionField(
                                  x.id,
                                  "article_number",
                                  (e.target as HTMLInputElement).value
                                )
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              size="small"
                              fullWidth
                              variant="outlined"
                              value={x.article_name}
                              onChange={(e) =>
                                setPositionField(
                                  x.id,
                                  "article_name",
                                  (e.target as HTMLInputElement).value
                                )
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              size="small"
                              fullWidth
                              variant="outlined"
                              value={x.plant}
                              onChange={(e) =>
                                setPositionField(
                                  x.id,
                                  "plant",
                                  (e.target as HTMLInputElement).value
                                )
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              size="small"
                              fullWidth
                              variant="outlined"
                              value={x.delivery_type}
                              onChange={(e) =>
                                setPositionField(
                                  x.id,
                                  "delivery_type",
                                  (e.target as HTMLInputElement).value
                                )
                              }
                            />
                          </TableCell>
                          <TableCell align="right">
                            <TextField
                              size="small"
                              fullWidth
                              variant="outlined"
                              value={x.amount}
                              onChange={(e) =>
                                setPositionField(
                                  x.id,
                                  "amount",
                                  (e.target as HTMLInputElement).value
                                )
                              }
                            />
                          </TableCell>
                          <TableCell align="right">
                            <TextField
                              size="small"
                              fullWidth
                              variant="outlined"
                              value={x.price}
                              onChange={(e) =>
                                setPositionField(
                                  x.id,
                                  "price",
                                  (e.target as HTMLInputElement).value
                                )
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    €
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>
                          <TableCell sx={{}}>
                            <IconButton
                              size="small"
                              onClick={() => deletePosition(x.id)}
                            >
                              <Delete />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions sx={{ borderTop: "1px solid #aaa" }}>
              <Button onClick={close}>Abbrechen</Button>
              <Button variant="outlined" color="primary" disabled={!valid} onClick={() => {save(parsedState); close();}}>
                Speichern
              </Button>
            </DialogActions>
          </Fragment>
        ) : (
          <Fragment>
            <DialogTitle
              sx={{
                p: 1,
                px: 2,
                background: "#002169",
                color: "white",
                borderBottom: "1px solid rgba(255,255,255,0.5)",
              }}
            >
              Auftragsbestätigung: Datei einlesen
            </DialogTitle>
            <DialogContent>
              <Paper variant="outlined" {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {isError ? (
                  <ErrorIcon color="error" />
                ) : isLoading ? (
                  <CircularProgress color="info" />
                ) : (
                  <Box sx={{ textAlign: "center", color: "#444" }}>
                    <PostAdd />
                    <Box>Auftragsbestätigung hier ablegen</Box>
                  </Box>
                )} 
              </Paper>
            </DialogContent>
            <DialogActions sx={{ borderTop: "1px solid #aaa" }}>
              <Button onClick={close}>Abbrechen</Button>
              <Button
                onClick={() =>
                  setParsedState({
                    supplier_name: "",
                    valid_from: null,
                    valid_until: null,
                    document_number: "",
                    articles: [],
                  })
                }
                variant="outlined"
                color="primary"
              >
                Manuelle Eingabe
              </Button>
            </DialogActions>
          </Fragment>
        )}
      </Dialog>
    </Fragment>
  );
}
