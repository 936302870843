import { Add } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Fragment, useCallback, useState } from "react";
import LabelWrapper from "../../root/LabelWrapper";
import ManyToOneSelect from "@/shared/forms/ManyToOneSelect";
import { Subcontractor } from "../Project";


export default function AddSubcontractorDialog({save}) {
  const [open, setOpen] = useState(false);

  const [comment, setComment] = useState("");
  const [subcontractor, setSubcontractor] = useState(null);
  const [subcontractorObject, setSubcontractorObject] = useState(null);

  
  const close = useCallback(() => {
    setOpen(false);
  }, []);

  const valid = !!subcontractor;

  return (
    <Fragment>
      <Button startIcon={<Add />} onClick={() => setOpen(true)}>
        Subunternehmen hinzufügen
      </Button>
      <Dialog open={open} maxWidth="lg" onClose={close}>
        
          <Fragment>
            <DialogTitle
              sx={{
                p: 1,
                px: 2,
                background: "#C5372C",
                color: "white",
                borderBottom: "1px solid rgba(255,255,255,0.5)",
              }}
            >
              Subunternehmen hinzufügen
            </DialogTitle>
            <DialogContent
              sx={{
                p: 0,
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <LabelWrapper label="Subunternehmen">
                <ManyToOneSelect<Subcontractor & { id: number }>
                size="small"
                entity={"subcontractor"}
                displayFunction={(o) => (!!o ? o.name : "(unbekannt)")}
                currentId={subcontractor ?? 0}
                sideEffect={setSubcontractorObject}
                setId={(id) =>
                  setSubcontractor(id > 0 ? id : null)
                }
                showWithoutChoice
                placeholder={"Kein Subunternehmer"}
              />
                </LabelWrapper>

                <LabelWrapper label="Kommentar">
                  <TextField
                    size="small"
                    fullWidth
                    value={comment}
                    placeholder="Kommentar"
                    multiline
                    onChange={(e) =>
                      setComment((e.target as HTMLInputElement).value)
                    }
                  />
                </LabelWrapper>
                
                  
            </DialogContent>
            <DialogActions sx={{ borderTop: "1px solid #aaa" }}>
              <Button onClick={close}>Abbrechen</Button>
              <Button variant="outlined" color="primary" disabled={!valid} onClick={() => {save({id: 0, deleted: false, comment, subcontractor: subcontractorObject}); close();}}>
                Speichern
              </Button>
            </DialogActions>
          </Fragment>
        
      </Dialog>
    </Fragment>
  );
}
