import { Button, Paper, Box } from "@mui/material";
import PlanningCalendar from "../calendarElements/PlanningCalendar";
import VerticalCalendar from "../../shared/VerticalCalendar";
import dayjs from "dayjs";
import { Add, FullscreenExitRounded } from "@mui/icons-material";
import { useState } from "react";
import { ProjectJob, ProjectPhase } from "../Project";
import PhaseEditor from "../editors/PhaseEditor";
import DayEditor from "../editors/DayEditor";

interface PlanSelection {
  date: string | null;
  phase: number;
}

export default function PlanningPage({ project, setField, initialSelection }) {
  const [currentSelection, setCurrentSelection2] = useState<PlanSelection>(initialSelection);

  const setCurrentSelection = (x) => {console.log(x); setCurrentSelection2(x);};

  const setCurrentPhaseField = (changes: Partial<ProjectPhase>) => {
    const phases = project.phases.slice();
    phases[currentSelection.phase] = {
      ...phases[currentSelection.phase],
      ...changes,
    };
    setField({ phases });
  };

  const setCurrentDayField = (changes: Partial<ProjectJob>) => {
    if (!currentSelection.date) return;
    const phases = project.phases.slice();
    phases[currentSelection.phase] = {
      ...phases[currentSelection.phase],
      jobs: {
        ...phases[currentSelection.phase].jobs,
        [currentSelection.date]: {
          ...phases[currentSelection.phase].jobs[currentSelection.date],
          ...changes,
        },
      },
    };
    setField({ phases });
  };

  const addPhase = () => {
    const phases = project.phases.slice();
    phases.push({
      id: 0,
      name: "",
      trade: null,
      position: null,
      plannedStart: null,
      plannedEnd: null,
      subcontractor: null,
      deleted: false,
      comment: "",
      jobs: {},
      resourceOrders: [
       /* {
          id: 0,
          deleted: false,
          amount: 1,
          tag: null,
          comment: "",
          type: "CREW",
          references: [null],
          auto: true,
          exceptions: []
        } */ //TODO AUTOKOLONNE
      ]
     } as ProjectPhase);
    setField({ phases });
    setCurrentSelection({ date: null, phase: phases.length - 1 });
  };

  let phaseExists = false;
  try {
    phaseExists =
      !currentSelection.date &&
      !!project.phases[currentSelection.phase] &&
      !project.phases[currentSelection.phase].deleted;
  } catch (e) {}

  let dayExists = false;
  try {
    dayExists =
      !!currentSelection.date &&
      !project.phases[currentSelection.phase].deleted &&
      !!project.phases[currentSelection.phase].jobs[currentSelection.date] &&
      !project.phases[currentSelection.phase].jobs[currentSelection.date]
        .deleted;
  } catch (e) {}

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        p: "10px",
        rowGap: "10px",
        overflow: "hidden",
      }}
    >
      <Paper sx={{ overflow: "hidden" }}>
        <VerticalCalendar
          initialCalEnd={dayjs(project.plannedEnd || "now").endOf("week")}
          initialCalStart={dayjs(project.plannedStart || "now").startOf("week")}
          buttons={
            <Button size="small" onClick={addPhase} startIcon={<Add />}>
              Bauphase
            </Button>
          }
        >
          <PlanningCalendar
            project={project}
            currentSelection={currentSelection}
            setCurrentSelection={setCurrentSelection}
            setField={setField}
          />
        </VerticalCalendar>
      </Paper>
      <Paper
        sx={{
          flex: 1,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          justifyContent: !phaseExists ? "center" : "initial",
        }}
      >
        {!phaseExists && !dayExists ? (
          <Box
            sx={{
              alignSelf: "center",
              textAlign: "center",
              maxWidth: "400px",
              p: 1,
              opacity: 0.5,
            }}
          >
            Wähle oder erstelle zuerst eine Bauphase
          </Box>
        ) : phaseExists ? (
          <PhaseEditor
            project={project}
            phaseId={currentSelection.phase}
            setField={setCurrentPhaseField}
          />
        ) : (
          <DayEditor
            project={project}
            phaseId={currentSelection.phase}
            date={currentSelection.date}
            setField={setCurrentDayField}
            setPhaseField={setCurrentPhaseField}
          />
        )}
      </Paper>
    </Box>
  );
}
