import { usePersistantStore, useGlobalStore } from "@/stores/global";
import RegionSelector from "./RegionSelector";
import BaseRootNav from "@/shared/structure/RootNav";
import BobDark from "@/assets/images/bob_dark.png";
import {
  LocalShippingTwoTone,
  CalendarMonthTwoTone,
  SettingsTwoTone,
  Home,
  CalendarMonth,
  Settings,
} from "@mui/icons-material";

export default function RootNav() {
  const setAuthenticated = usePersistantStore(
    (state) => state.setAuthenticated
  );

  const pageTitle = useGlobalStore((state) => state.pageTitle);

  return (
    <BaseRootNav
      image={BobDark}
      name={pageTitle}
      menu={[
        {
          Icon: Home,
          to: "/",
          text: "Übersicht",
          end: true,
        },{
          Icon: CalendarMonth,
          to: "calendar",
          text: "Kalender",
          end: true,
        },{
          Icon: Settings,
          to: "settings",
          text: "Stammdaten",
          end: false,
        },
        
      ]}
      setAuthenticated={setAuthenticated}
      elements={[<RegionSelector />]}
    />
  );
}
