import { useGlobalStore } from "@/stores/global";
import { Box, IconButton, Paper } from "@mui/material";
import { Fragment, useCallback, useEffect, useState } from "react";
import VerticalCalendar from "../shared/VerticalCalendar";
import SelectCalendarDialog from "./dialogs/SelectCalendarDialog";
import InnerCalendar from "./calendarElements/InnerCalendar";
import dayjs from "dayjs";
import { AddCircle } from "@mui/icons-material";
import AddFastResourceDialog from "./dialogs/AddFastResource";

export type PossibleCalendarTypes =
  | "PROJECTS"
  | "CREWS"
  | "STAFF"
  | "RESOURCES"
  | "MATERIAL"
  | "DISPO";

export default function MainCalendar() {
  const setPageTitle = useGlobalStore((state) => state.setPageTitle);

  const [type, setType] = useState<PossibleCalendarTypes | null>(null);
  const [reference, setReference] = useState<number | null>(null);
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => setPageTitle("Kalender"), []);

  const createDayButtons = useCallback(
    (date) => (
      <IconButton onClick={() => setSelectedDate(date)}>
        <AddCircle />
      </IconButton>
    ),
    []
  );

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        p: 1,
        background: "#eee",
      }}
    >
      <Paper
        sx={{ overflow: "hidden", display: "flex", flexDirection: "column" }}
      >
        <VerticalCalendar
          initialCalEnd={dayjs().endOf("week")}
          initialCalStart={dayjs().startOf("week")}
          dateButtons={["CREWS", "STAFF", "RESOURCES"].includes(type) ? createDayButtons : null}
          buttons={
            <SelectCalendarDialog
              type={type}
              reference={reference}
              onChange={(type, reference) => {
                setType(type);
                setReference(reference);
              }}
            />
          }
        >
          {!!type ? <InnerCalendar type={type} reference={reference} /> : <Fragment />}
        </VerticalCalendar>
        {["CREWS", "STAFF", "RESOURCES"].includes(type) ? (
          <AddFastResourceDialog
            date={selectedDate}
            type={type}
            reference={reference}
            close={() => setSelectedDate(null)}
          />
        ) : null}
      </Paper>
    </Box>
  );
}
