import { ArrowDropDown } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { PossibleCalendarTypes } from "../MainCalendar";
import useReferenceNames from "@/shared/forms/useReferenceNames";

interface Tag {
  id: number;
  type: "RESOURCE" | "HUMAN";
  name: string;
}
interface Plant {
  supplierName: string;
  id: number;
  name: string;
}

export default function SelectCalendarDialog({
  type,
  reference,
  onChange,
}: {
  type: PossibleCalendarTypes;
  reference: number | null;
  onChange: (type: PossibleCalendarTypes, reference: number | null) => void;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const open = !!anchorEl;
  const open2 = !!anchorEl2;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const close = useCallback(() => {
    setAnchorEl(null);
    setAnchorEl2(null);
  }, []);

  const tagNames = useReferenceNames<Tag, Tag>("resourceTag", (o) => o);

  const [resources, staffs, crews] = useMemo(() => {
    const r: Tag[] = [];
    const s: Tag[] = [];
    const c: Tag[] = [];
    for (const t of tagNames.values()) if (["CALENDAR_ONLY","WISHABLE","REQUESTABLE"].includes(t?.calendarType || "")) (t.type === "HUMAN" ? s : t.type === "RESOURCE" ? r : c).push(t);
    return [r, s, c];
  }, [tagNames]);

  const plantNames = useReferenceNames<Plant, Plant>("plant", (o) => o);


  useEffect(() => {
    console.log("callback",type,crews);
    if (!type && crews.length > 0) onChange("CREWS", crews[0].id);
  }, [type, crews]);


  const title =
    type === "CREWS"
      ? "Kolonnen"
      : type === "STAFF"
      ? "Mitarbeiter"
      : type === "RESOURCES"
      ? "Maschinen"
      : type === "PROJECTS"
      ? "Baustellen"
      : type === "DISPO"
      ? "Disposition"
      : type === "MATERIAL" ?
       "Material" : "Lädt...";

  const hasSecondMenu =
    type === "STAFF" || type === "RESOURCES" || type === "MATERIAL" || type === "CREWS";

  const title2 = !hasSecondMenu
    ? ""
    : type === "MATERIAL"
    ? ((x) => (!!x ? x.name || x.supplierName : "Unbekannt"))(
        plantNames.get(reference ?? 0)
      )
    : tagNames.get(reference ?? 0)?.name || "Unbekannt";

  return (
    <Fragment>
      <Button onClick={handleClick} endIcon={<ArrowDropDown />}>
        {title}
      </Button>
      {hasSecondMenu ? (
        <Button onClick={handleClick2} endIcon={<ArrowDropDown />}>
          {title2}
        </Button>
      ) : null}
      {hasSecondMenu ? (
        <Menu
          open={open2}
          anchorEl={anchorEl2}
          onClose={close}
          slotProps={{
            paper: {
              style: {
                maxHeight: "400px",
              },
            },
          }}
        >
          {(type === "MATERIAL"
            ? Array.from(plantNames.values())
            : type === "RESOURCES"
            ? resources :
              type === "CREWS" ? 
              crews
            : staffs
          ).map((x) => (
            <MenuItem
              key={x.id}
              onClick={() => {
                onChange(type, x.id);
                close();
              }}
            >
              {x.name || x?.supplierName || "Unbekannt"}
            </MenuItem>
          ))}
        </Menu>
      ) : null}
      <Menu open={open} anchorEl={anchorEl} onClose={close}>
        <MenuItem
          onClick={() => {
            type === "PROJECTS" || onChange("PROJECTS", null);
            close();
          }}
        >
          Baustellen
        </MenuItem>
        <MenuItem
          onClick={() => {
            type === "CREWS" || onChange("CREWS", crews.length > 0 ? crews[0].id : 0);
            close();
          }}
        >
          Kolonnen
        </MenuItem>
        <MenuItem
          onClick={() => {
            type === "RESOURCES" ||
              onChange("RESOURCES", resources.length > 0 ? resources[0].id : 0);
            close();
          }}
        >
          Maschinen
        </MenuItem>
        <MenuItem
          onClick={() => {
            type === "STAFF" ||
              onChange("STAFF", staffs.length > 0 ? staffs[0].id : 0);
            close();
          }}
        >
          Mitarbeiter
        </MenuItem>
        <MenuItem
          onClick={() => {
            type === "MATERIAL" ||
              onChange(
                "MATERIAL",
                plantNames.size > 0 ? plantNames.keys().next().value : 0
              );
            close();
          }}
        >
          Material
        </MenuItem>
        <MenuItem
          onClick={() => {
            type === "DISPO" || onChange("DISPO", null);
            close();
          }}
        >
          Disposition
        </MenuItem>
      </Menu>
    </Fragment>
  );
}
