import { Paper, Button, Typography, Box, IconButton, Alert, AlertTitle } from "@mui/material";
import { Fragment, useCallback } from "react";
import { Add, Delete, Edit, Message, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  initialResourceOrder,
  ResourceOrder,
  WithExceptions,
} from "../Project";
import EnhancedTable from "@/shared/views/EnhancedTable";
import { TopBar } from "@/shared/structure/TopBar";
import AddResourceDialog from "../dialogs/AddResourceDialog";
import useReferenceNames from "@/shared/forms/useReferenceNames";

const headCells = [
  {
    id: "amount",
    label: "Menge",
    numeric: true,
    sortable: true,
  },{
    id: "_category",
    label: "Kategorie",
    numeric: false,
    sortable: true,
  },
  
  {
    id: "_wish",
    label: "Wunschauswahl",
    numeric: false,
    sortable: true,
  },
  {
    id: "_comment",
    label: "Weitere Informationen",
    numeric: false,
    sortable: true,
  },
];

export default function ResourceTab({
  phaseState,
  setPhaseState,
  jobState = [],
  setJobState = (_e) => {},
  jobUuid = null,
}: {
  phaseState: (ResourceOrder & WithExceptions)[];
  setPhaseState: (data: (ResourceOrder & WithExceptions)[]) => void;
  jobState: ResourceOrder[];
  setJobState: (data: ResourceOrder[]) => void;
  jobUuid: string | null;
}) {
  const deletePhaseOrder = useCallback(
    (index: number) => {
      setPhaseState(
        phaseState.slice().map((so, i) => ({
          ...so,
          deleted: !!so.deleted || i === index,
        }))
      );
    },
    [phaseState]
  );

  const deleteJobOrder = useCallback(
    (index: number) => {
      setJobState(
        jobState.slice().map((so, i) => ({
          ...so,
          deleted: !!so.deleted || i === index,
        }))
      );
    },
    [jobState]
  );

  const savePhaseOrder = useCallback(
    (subOrder: ResourceOrder & WithExceptions, index: number) => {
      if (index === -1) {
        const newState = phaseState.slice();
        newState.push(subOrder);
        setPhaseState(newState);
      } else {
        setPhaseState(
          phaseState.slice().map((so, i) => (i === index ? subOrder : so))
        );
      }
    },
    [phaseState]
  );

  const saveJobOrder = useCallback(
    (subOrder: ResourceOrder, index: number) => {
      if (index === -1) {
        const newState = jobState.slice();
        newState.push(subOrder);
        setJobState(newState);
      } else {
        setJobState(
          jobState.slice().map((so, i) => (i === index ? subOrder : so))
        );
      }
    },
    [jobState]
  );

  const addException = useCallback(
    (index: number) => {
      if (!jobUuid) return;
      setPhaseState(
        phaseState.slice().map((so, i) => ({
          ...so,
          exceptions:
            i === index
              ? so.exceptions.slice().concat([jobUuid])
              : so.exceptions,
        }))
      );
    },
    [phaseState, jobUuid]
  );

  const removeException = useCallback(
    (index: number) => {
      if (!jobUuid) return;
      setPhaseState(
        phaseState.slice().map((so, i) => ({
          ...so,
          exceptions:
            i === index
              ? so.exceptions.slice().filter((x) => x !== jobUuid)
              : so.exceptions,
        }))
      );
    },
    [phaseState, jobUuid]
  );

  const hasJobOrders = jobState.filter((x) => !x.deleted).length > 0;
  const hasPhaseOrders = phaseState.filter((x) => !x.deleted).length > 0;

  const tagNames = useReferenceNames("resourceTag");
  const crewNames = useReferenceNames("crew");
  const resourceNames = useReferenceNames("resource");
  const staffNames = useReferenceNames("staffMember");

  const rowFormatter = useCallback((row: ResourceOrder) => ({
    ...row,
    _className: !!row.exceptions && row.exceptions.includes(jobUuid) ? "strikedOut" : "",
    _comment: row.comment || <em style={{color: "#666"}}>-</em>,
    _category: row.type === "CREW" ? "Kolonne" : row.type === "HUMAN" ? "Zusätzlicher Mitarbeiter" : tagNames.get(row.tag ?? 0) ?? "unbekannt",
    _wish: row.references.map(ref => !!ref ? (row.type === "CREW" ? crewNames : row.type === "HUMAN" ? staffNames : resourceNames).get(ref) : null).filter(x => !!x).join(", ") || <em style={{color: "#666"}}>-</em>
  }), [tagNames, crewNames, resourceNames, staffNames, jobUuid]);

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
      }}
    >
      <TopBar>
        {!!jobUuid ? (
          <AddResourceDialog<ResourceOrder>
            save={(o) => saveJobOrder(o, -1)}
            OpenButton={({ onClick }) => (
              <Button startIcon={<Add />} onClick={onClick}>
                Bestellung hinzufügen
              </Button>
            )}
            initialState={initialResourceOrder()}
          />
        ) : (
          <AddResourceDialog<ResourceOrder & WithExceptions>
            save={(o) => savePhaseOrder(o, -1)}
            OpenButton={({ onClick }) => (
              <Button startIcon={<Add />} onClick={onClick}>
                Eintrag hinzufügen
              </Button>
            )}
            initialState={{
              ...initialResourceOrder(),
              exceptions: [],
            }}
          />
        )}
      </TopBar>
      <Box sx={{ flex: 1, overflow: "auto", background: "#f5f5f5", padding: 2 }}>
        {!hasJobOrders && !hasPhaseOrders ? <Alert severity="info" variant="filled">
                            <AlertTitle>Nichts gefunden.</AlertTitle>
                            Hier wurde noch nichts bestellt.
                          </Alert> : null}
        {hasJobOrders ? (
          <Typography variant="h6" gutterBottom>
            Heute
          </Typography>
        ) : null}
        {hasJobOrders ? (
          <Paper>
            <EnhancedTable
              initialRows={100}
              buttons={(row) => (
                <Fragment>
                  <AddResourceDialog<ResourceOrder>
                    save={(order) => saveJobOrder(order, row.index)}
                    initialState={jobState[row.index]}
                    OpenButton={({ onClick }) => (
                      <IconButton size="small" onClick={onClick}>
                        <Edit />
                      </IconButton>
                    )}
                  />
                  <IconButton
                    size="small"
                    onClick={() => deleteJobOrder(row.index)}
                  >
                    <Delete />
                  </IconButton>
                </Fragment>
              )}
              type=""
              noEmptyCells
              initialSort="_category"
              headCells={headCells}
              rowFormatter={rowFormatter}
              rows={jobState
                .map((x, index) => ({ ...x, index }))
                .filter((x) => !x.deleted)}
            />
          </Paper>
        ) : null}
        {hasPhaseOrders && !!jobUuid ? (
          <Typography sx={{ mt: hasJobOrders ? 2: 0 }} variant="h6" gutterBottom>
            Bauphase
          </Typography>
        ) : null}
        {hasPhaseOrders ? (
          <Paper>
            <EnhancedTable
              initialRows={100}
              buttons={
                !!jobUuid
                  ? (row) => (
                      <Fragment>
                        {row.exceptions.includes(jobUuid) ? (
                          <IconButton
                            size="small"
                            onClick={() => removeException(row.index)}
                          >
                            <Visibility />
                          </IconButton>
                        ) : (
                          <IconButton
                            size="small"
                            onClick={() => addException(row.index)}
                          >
                            <VisibilityOff />
                          </IconButton>
                        )}
                      </Fragment>
                    )
                  : (row) => (
                      <Fragment>
                        <AddResourceDialog<ResourceOrder & WithExceptions>
                          save={(order) => savePhaseOrder(order, row.index)}
                          initialState={phaseState[row.index]}
                          OpenButton={({ onClick }) => (
                            <IconButton size="small" onClick={onClick}>
                              <Edit />
                            </IconButton>
                          )}
                        />
                        <IconButton
                          size="small"
                          onClick={() => deletePhaseOrder(row.index)}
                        >
                          <Delete />
                        </IconButton>
                      </Fragment>
                    )
              }
              type=""
              noEmptyCells
              initialSort="_category"
              headCells={headCells}
              rowFormatter={rowFormatter}
              rows={phaseState
                .map((x, index) => ({ ...x, index }))
                .filter((x) => !x.deleted)}
            />
          </Paper>
        ) : null}
      </Box>
    </Box>
  );
}
