import {
  Box,
  Button,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import dayjs from "dayjs";
import LabelWrapper from "../../root/LabelWrapper";
import { PositionEditor } from "./PositionEditor";
import {
  initialPosition,
  ProjectJob,
  ProjectPhase,
  ProjectState,
  SitePosition,
} from "../Project";
import { Delete, WrongLocation } from "@mui/icons-material";
import { useState } from "react";
import ResourceTab from "./ResourceTab";
import MaterialTab from "./MaterialTab";
import TimePicker from "react-time-picker";

export const gewerke = {
  asphalt: { id: "asphalt", name: "Asphalt" },
  bau: { id: "bau", name: "Bau" },
  sonstiges: { id: "sonstiges", name: "Sonstiges" },
};

export default function DayEditor({
  project,
  phaseId,
  date,
  setPhaseField,
  setField,
}: {
  phaseId: number;
  date: string;
  project: ProjectState;
  setPhaseField: (changes: Partial<ProjectPhase>) => void;
  setField: (changes: Partial<ProjectJob>) => void;
}) {
  const [currentPage, setCurrentPage] = useState<
    "JOB" | "RESOURCES" | "MATERIAL" | "TRANSPORT"
  >("JOB");

  const phase = project.phases[phaseId];
  const job = project.phases[phaseId].jobs[date];

  if (phase.deleted || job.deleted) return null;

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          borderTop: "1px solid rgba(0,0,0,0.2)",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            borderLeft: "1px solid rgba(0,0,0,0.2)",
            borderRight: "1px solid #aaa",
            background: "#edf0f9",
            borderBottom: "1px solid #aaa",
            boxShadow: "inset 0px -4px 5px rgba(0,0,0,0.1)",
            px: 2,
            fontSize: "15px",
            textTransform: "uppercase",
            fontWeight: "bold",
          }}
        >
          <div>{dayjs(date, "YYYY-MM-DD").format("ddd, DD. MMMM YYYY")}</div>
        </Box>
        <ToggleButtonGroup
          sx={{
            background: "#edf0f9",
            ".MuiToggleButtonGroup-firstButton": {
              borderLeft: "none !important",
            },
            ".MuiToggleButtonGroup-groupedHorizontal.Mui-selected": {
              background: "white !important",
              borderBottom: "none",
              borderLeft: "1px solid #aaa",
            },
            ".MuiToggleButtonGroup-groupedHorizontal": {
              borderRadius: 0,
              borderTop: "none",
              borderBottom: "1px solid #aaa",
              borderRight: "1px solid #aaa",
            },
          }}
          orientation="horizontal"
          exclusive
          value={currentPage}
          onChange={(_, val) => val && setCurrentPage(val)}
        >
          <ToggleButton value="JOB">Tag</ToggleButton>
          <ToggleButton value="RESOURCES">Personal & Maschinen</ToggleButton>
          <ToggleButton value="MATERIAL">Material</ToggleButton>
        </ToggleButtonGroup>
        <Box
          sx={{
            flex: 1,
            background: "#edf0f9",
            borderRight: "1px solid rgba(0,0,0,0.2)",
            borderBottom: "1px solid #aaa",
            boxShadow: "inset 0px -4px 5px rgba(0,0,0,0.1)",
          }}
        ></Box>
      </Box>
      {currentPage === "JOB" ? (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            overflow: "hidden",
            alignItems: "stretch",
          }}
        >
          <Box
            sx={{
              minWidth: "400px",
              flex: 1,
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <LabelWrapper label="Status">
              <ToggleButtonGroup
                sx={{
                  ".Mui-selected": {
                    background:
                      job.status === "PLANNING"
                        ? "#99D4FF"
                        : job.status === "RAW"
                        ? "#A6FF99"
                        : "#FF9999",
                  },
                }}
                orientation="horizontal"
                exclusive
                value={job.status}
                onChange={(_, status) =>
                  status &&
                  setField({
                    status,
                  })
                }
              >
                <ToggleButton value="PLANNING">Planung</ToggleButton>
                <ToggleButton value="RAW">Grob</ToggleButton>
                <ToggleButton value="FINE">Fein</ToggleButton>
              </ToggleButtonGroup>
            </LabelWrapper>
            <LabelWrapper label="Arbeitsbeginn">
              <TimePicker
                onChange={(startTime) =>
                  setField({
                    startTime,
                  })
                }
                className={!job.startTime ? "nostart2" : ""}
                disableClock
                value={job.startTime}
              />
            </LabelWrapper>
            <LabelWrapper label="Beschreibung">
              <TextField
                size="small"
                fullWidth
                multiline
                value={job.comment}
                placeholder="Beschreibung"
                onChange={(e) =>
                  setField({
                    comment: (e.target as HTMLInputElement).value,
                  })
                }
              />
            </LabelWrapper>
            <div style={{ flex: 1 }} />
            <Button
              sx={{ alignSelf: "flex-start", m: 1 }}
              startIcon={<Delete />}
              color="error"
              variant="outlined"
              onClick={() => setField({ deleted: true })}
            >
              Tag löschen
            </Button>
          </Box>
          <Box
            id="mapContainer"
            sx={{
              flex: 10,
              borderLeft: "1px solid #aaa",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ position: "relative", flex: 1 }}>
              <PositionEditor
                position={(job.position ?? phase.position ?? project.position) || initialPosition}
                onChange={(changes: Partial<SitePosition>) =>
                  setField({
                    position: {
                      ...((job.position ?? phase.position ?? project.position) || initialPosition),
                      ...changes,
                    },
                  })
                }
              />
            </Box>
            {!!job.position ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: 1,
                  borderTop: "1px solid #aaa",
                  color: "#777",
                  fontStyle: "italic",
                  fontSize: "80%",
                  alignItems: "center",
                }}
              >
                <Box>Angepasster Ort</Box>
                <Button
                  size="small"
                  onClick={() =>
                    setField({
                      position: null,
                    })
                  }
                  variant="outlined"
                  startIcon={<WrongLocation />}
                >
                  zurücksetzen
                </Button>
              </Box>
            ) : null}
          </Box>
        </Box>
      ) : currentPage === "RESOURCES" ? (
        <ResourceTab
          phaseState={phase.resourceOrders}
          setPhaseState={(resourceOrders) => setPhaseField({ resourceOrders })}
          jobState={job.resourceOrders}
          setJobState={(resourceOrders) => setField({ resourceOrders })}
          jobUuid={job.uuid}
        />
      ) : currentPage === "MATERIAL" ? (
        <MaterialTab
          supplierOrders={project.supplierOrders}
          jobState={job.materialOrders}
          setJobState={(materialOrders) => setField({ materialOrders })}
        />
      ) : null}
    </Box>
  );
}
